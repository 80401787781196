//import libraries
import React from "react";
import ToggleDisplay from 'react-toggle-display';

class DkCard3 extends React.Component {
  constructor() {
    super();
    this.state = { 
      show:false
     };
  }

  handleClick() {
    this.setState({show: !this.state.show});
  }

  render(){
  return (
    <React.Fragment>
    {/* 3rd row - design */}
      <div
      data-sal="slide-up" 
      data-sal-delay="100"
      data-sal-easing="ease"
      data-sal-duration="1000">

      <div className="stack-subheading">
        <h3> design meaningful and relevant product for your users </h3> 
        <p> utilizing extensive marketing experience  </p>
        <div className="stack-switch-flex">
            <label className="switch">
              <input type="checkbox" onClick={ () => this.handleClick() }/>
              <span className="slider"></span>
            </label>
              <p className="switch-legend">view my stack</p>
          </div>
      </div>

    <ToggleDisplay show={this.state.show}>
{/* 3rd row - cards */}
      <div className="cards-stack1">
        <div className="stack-row mb-5">
          <div className="col mb-4">
            <div className="card">
              <h5>Figma</h5>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card">
              <h5>Photoshop</h5>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card">
              <h5>InDesign</h5>
            </div>
          </div>
          <div className="col mb-4">
            <div className="card">
              <h5>Illustrator</h5>
            </div>
          </div>
        </div>
      </div>
    </ToggleDisplay>
{/* 3rd row - end cards */}
  </div>
  {/* end 3rd row - design */}

  </React.Fragment>


  )
  }
}
export default DkCard3