//import libraries
import React from "react";
import Modal from 'react-responsive-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "gatsby";
import MediaQuery from 'react-responsive';
import {Motion, spring} from 'react-motion';


class Portfolio3 extends React.Component {
  constructor () {
    super();
    this.state = {
      open: false,
      isHover: false,
      activeItem: null
    };
    this.handleHover = this.handleHover.bind(this);
    this.getSpringProps = this.getSpringProps.bind(this);
  }
  
  handleHover(item) {
    this.setState({activeItem: item, isHover: !this.state.isHover});
  }

  getSpringProps() {
    return {
      defaultStyle: {
        scale: 1.15,
        marginTop: 30,
        imageOpacity: 0.9,
        opacity: 0,             
      },
      style:{
        scale: spring(this.state.isHover ? 1 : 1.15),
        marginTop: spring(this.state.isHover ? 30 : 30), 
        imageOpacity: spring(this.state.isHover ? 0.2 : 0.9),
        opacity: spring(this.state.isHover ? 1 : 0),
      },
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>
{/* START desktop animated card with media query */}  
        <MediaQuery minDeviceWidth={600}>
          <div 
            data-sal-easing="ease"
            data-sal="slide-right" 
            data-sal-duration="1000"
            className="w-animated-card-L">

            <div className="img-relative">
            <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1578438441/portfolio/bg-work-left-3_zdbgwa.svg'} alt="DixNeuf" className="w-img-L"/>
              
              <div className="w-card-font w-card-abs-L">
                <p> WEDDING SITE
                <br/>
                <span>complex dynamic form</span>
                </p>
                <Link className="btn-learn" to="/" onClick={this.onOpenModal}><span>see details</span></Link>
              </div>

            </div>
          </div>
        </MediaQuery>
{/* END desktop animated card with media query */}


          <MediaQuery maxDeviceWidth={599}>
            <div className="animated-card-left">
              <Motion {...this.getSpringProps()}>
                  {tweenCollection => {
                  let styleImage = {
                    transform: 'scale(' + tweenCollection.scale + ')',
                    opacity: tweenCollection.imageOpacity, 
                  };
                  let styleTitle = {
                      marginTop: tweenCollection.marginTop + '%',
                      opacity: tweenCollection.opacity,
                  };
                  return (
                    <div className='subcontainer'>
                      <div
                        className='containerImage'
                        onTouchStart={this.handleHover.bind(null, true)}>
                        <img
                          style={styleImage}
                          src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579566419/portfolio/mobil/mb-portfolio-dixneuf_kxry0n.jpg'}
                          alt="DixNeuf"
                          className='img img-left img-mobile' />
                        <div className='overlay'>
                          <div className='title'>Wedding Site</div>
                          <div className='subtitle' style={styleTitle}> complex dynamic form <br/> React.js {'&'} Airtable
                            <div className='subtitleText'><Link className="btn-learn-motion" to="/" onClick={this.onOpenModal}>see details</Link></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Motion>
            </div>
          </MediaQuery>
          <Modal open={open} onClose={this.onCloseModal} closeIconSize={20} closeIcon="closeIcon" center>
            <div>
              <video 
                muted
                playsInline
                controls 
                style={{ width: `100%` }}>
                  <source src={"https://res.cloudinary.com/kristynadierstein/video/upload/v1579615847/portfolio/video/dixneuf-video_r8xuvc.mp4"} type="video/mp4" />
                <source src={"https://res.cloudinary.com/kristynadierstein/video/upload/v1579615846/portfolio/video/dixneuf-video_gyquuu.webm"} type="video/webm" />
                <track kind="metadata" src="#" srcLang="en" label="DixNeuf1"/>
                Sorry, your browser doesn't support embedded videos.
              </video>
              <div className="legend-modal">
                <div>
                  <h5>
                    <b>DixNeufOctobre</b>
                  </h5>
                  <p> 
                  Information website for wedding participants. 
                  <br />
                  Dynamic form to organize a carpool where participants shared their needs for a car or proposed a ride. 
                  <br/>
                  Different questions in the form appeared based on participants{"'"} answers. 
                  <br />
                  <br/>
                  Built with Gatsby and React.js, data gathered in Airtable. </p>
                  <div className="div-btn-modal">
                    <p><a href={"https://github.com/kristynadierstein/savethedate"} className="btn-learn-modal">view on GitHub </a></p>
                  </div>
                </div> 
              </div>
            </div>
          </Modal>
      </React.Fragment>
    );
  }
}

export default Portfolio3


