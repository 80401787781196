//import libraries
import React from "react";
import ToggleDisplay from 'react-toggle-display';

class DkCard1 extends React.Component {
  constructor() {
    super();
    this.state = { 
      show:false
     };
  }

  handleClick() {
    this.setState({show: !this.state.show});
  }

  render(){
  return ( 
  
  <React.Fragment>
    <section className="section-centered-headline">
      <h1> what can I do for you? </h1>
    </section>
    
    {/* 1st row - create your website */}
    <div 
      data-sal="slide-up" 
      data-sal-delay="100"
      data-sal-easing="ease"
      data-sal-duration="1000">
      
      <div className="stack-subheading">
        <h3> create your website </h3> 
        <p> from a simple one pager to more complex dynamic platforms with database, mailing and other features </p>
      
        <div className="stack-switch-flex">
          <label className="switch">
            <input type="checkbox" onClick={ () => this.handleClick() }/>
            <span className="slider"></span>
          </label>
            <p className="switch-legend">view my stack</p>
        </div>
       
       {/* <button onClick={ () => this.handleClick() }>this is a button to click on</button> */}
      </div>

        
      <ToggleDisplay show={this.state.show}>
{/* 1st row - cards */}
        <div className="cards-stack1">
          <div className="stack-row mb-5">
            <div className="col mb-4">
              <div className="card">
                <h5>HTML5</h5>
              </div>
            </div>

            <div className="col mb-4">
              <div className="card">
                <h5>CSS3</h5>
              </div>
            </div>

            <div className="col mb-4">
              <div className="card">
                <h5>JavaScript</h5>
              </div>
            </div>

            <div className="col mb-4">
              <div className="card">
                <h5>React.js</h5>
              </div>
            </div>

            <div className="col mb-4">
              <div className="card">
                <h5>Ruby/Rails</h5>
              </div>
            </div>

            <div className="col mb-4">
              <div className="card">
                <h5>GraphQl</h5>
              </div>
            </div>
          </div>
          </div>
        </ToggleDisplay >
        {/* 1st row - end cards */}
      </div>
{/* end 1st row - create your website */}
  </React.Fragment>
  )
};
}

export default DkCard1
    