//import libraries
import React from "react";


//import icons
import { FaLinkedin, FaGithub, FaMailBulk } from "react-icons/fa";


class Footer extends React.Component {
  render () {
  return (
    <React.Fragment>
      <div className=" footer ">

        <div className="div-form">
          <form name="contact" method="post" data-netlify="true" data-netlify-honeypot="bot-field"className="contact-form">
          <input type="hidden" name="form-name" value="contact" />
          <input type="text" name="name" className="form-field" placeholder="name"/>
          <input type="email" name="email" className="form-field" placeholder="enter email" />
          <textarea name="message" className="form-field" placeholder=" your message"></textarea>
          <button type="submit" className="btn-send-form">send</button>
          </form>
        </div>

        <div className="social-media">
          <p><a href={"mailto:kristynadierstein@gmail.com"}><FaMailBulk /> </a></p>
          <p> <a href={"http://www.linkedin.com/in/kristynadierstein"}><FaLinkedin /></a> </p>
          <p> <a href={"https://github.com/kristynadierstein"}><FaGithub /></a> </p>
        </div>

        
      </div>

      <div className="footer-muted">
        <p className="muted"> kristyna dierstein©2020</p>
      </div>
    </React.Fragment>
  )
}
}

export default Footer