//import libraries
import React from "react";
import Modal from 'react-responsive-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "gatsby";
import MediaQuery from 'react-responsive';
import {Motion, spring} from 'react-motion';

class Portfolio4 extends React.Component {
  constructor() {
    super();
      this.state = {
      open: false,
      isHover:false,
      activeItem: null
    };
  this.handleHover = this.handleHover.bind(this);
  this.getSpringProps = this.getSpringProps.bind(this);
  }

  handleHover(item) {
    this.setState({activeItem: item, isHover: !this.state.isHover});
  }

  getSpringProps() {
    return {
      defaultStyle: {
        scale: 1.15,
        marginTop: 30,
        imageOpacity: 0.9,
        opacity: 0,             
      },
      style:{
        scale: spring(this.state.isHover ? 1 : 1.15),
        marginTop: spring(this.state.isHover ? 30 : 30), 
        imageOpacity: spring(this.state.isHover ? 0.2 : 0.9),
        opacity: spring(this.state.isHover ? 1 : 0),
      },
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>       
{/* START desktop animated card with media query */}  
        <MediaQuery minDeviceWidth={600}>
          <div 
            data-sal-easing="ease"
            data-sal="slide-left" 
            data-sal-duration="1000"
            className="w-animated-card-R">
            
            <div className="img-relative">
              <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1578504559/portfolio/bg-work-right-4_fxmixz.svg'} alt="WordPress" className="w-img-R"/>
                <div className="w-card-font w-card-R">
                  <p> WORDPRESS
                  <br/>
                  <span>content management</span>
                  </p>
                  <Link className="btn-learn" to="/" onClick={this.onOpenModal}><span>see details</span></Link>
                </div>
            </div>
            
          </div>
        </MediaQuery>
{/* END desktop animated card with media query */}

        <MediaQuery maxDeviceWidth={599}>
          <div className="animated-card-right">
            <Motion {...this.getSpringProps()}>
              {tweenCollection => {
              let styleImage = {
                transform: 'scale(' + tweenCollection.scale + ')',
                opacity: tweenCollection.imageOpacity, 
              };
              let styleTitle = {
                  marginTop: tweenCollection.marginTop + '%',
                  opacity: tweenCollection.opacity,
              };
              return (
                <div className='subcontainer'>
                <div
                  className='containerImage'
                  onTouchStart={this.handleHover.bind(null, true)}>
                  <img
                    style={styleImage}
                    src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579565995/portfolio/mobil/mb-portfolio-wp_z5k2u5.jpg'}
                    alt="Word Press"
                    className='img img-right img-mobile' />
                  <div className='overlay'>
                    <div className='title'>WordPress</div>
                    <div className='subtitle' style={styleTitle}> content management <br/> WordPress
                      <div className='subtitleText'><Link className="btn-learn-motion" to="/" onClick={this.onOpenModal}>see details</Link></div>
                    </div>
                  </div>
                </div>
                </div>
              );
              }}
            </Motion>
          </div>
        </MediaQuery>
        
        <Modal open={open} onClose={this.onCloseModal} closeIconSize={20} closeIcon="closeIcon" center>
          <Carousel
          showThumbs={false}
          showStatus={false}  
          dynamicHeight={true}>
            <div>
              <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125123/portfolio/carousel/carousel-wp-1_kifet0.jpg'} alt="carousel-wp-1"/>
            </div>
            <div>
              <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125123/portfolio/carousel/carousel-wp-2_cbs6zw.jpg'} alt="carousel-wp-2"/>
            </div>
            <div>
              <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125123/portfolio/carousel/carousel-wp-3_hkmuyh.jpg'} alt="carousel-wp-3"/>
            </div>
            <div>
              <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125123/portfolio/carousel/carousel-wp-4_yhba97.jpg'} alt="carousel-wp-4" />
            </div>
          </Carousel>
          <div className="legend-modal">
            <div>
              <h5>
                <b>Content management</b>
              </h5>
              <p> 
              Client's support in creating templates, forms or localization of content 
              <br/>
              in web-development platforms such as WordPress or Wix. </p>
              <div className="div-btn-modal">
                <p><a href={"https://evolupharma.ca/"} className="btn-learn-modal">visit Evolupharma.ca </a></p>
                <p><a href={"https://crea-med.ca/en/"} className="btn-learn-modal">visit Crea-Med.ca </a></p>
              </div>
            </div>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default Portfolio4



