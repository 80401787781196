//import libraries
import React from "react";

//import imagery
import profiledk from "../images/photo_profil.jpg"


const AboutMeMobile = () => (
    <React.Fragment>
      <div className="padding-top-id">
        <div className="section-centered-headline">
          <h1> hi! </h1>
        </div>
        <div className="mobile-flex-center">
        <img src={profiledk} alt="mobile-profile" className="mobile-aboutme-img"/>
          <div className="mobile-aboutme-div">
            <p className="aboutme-copy"> I am a <b>front-end developer {"&"} designer </b> with 10-years of marketing experience as a creative leader at large multinational corporations such as Coty, L'Oreal or Young {"&"} Rubicam. 
        I utilize my <b>extensive marketing background</b> in everything I code or design so I can create a user-centered products that align with client business objectives.</p>
          </div>
        </div>
      </div>
    </React.Fragment>
      
  );

export default AboutMeMobile 

