//import libraries
import React from "react";
import ToggleDisplay from 'react-toggle-display';

class DkCard2 extends React.Component {
  constructor() {
    super();
    this.state = { 
      show:false
     };
  }

  handleClick() {
    this.setState({show: !this.state.show});
  }

  render(){
  return (
    <React.Fragment>
      <div         
        data-sal="slide-up" 
        data-sal-delay="100"
        data-sal-easing="ease"
        data-sal-duration="1000">

        <div className="stack-subheading ">
          <h3> implement CMS </h3> 
          <p> via popular web development platforms  </p>
          <div className="stack-switch-flex">
            <label className="switch">
              <input type="checkbox" onClick={ () => this.handleClick() }/>
              <span className="slider"></span>
            </label>
              <p className="switch-legend">view my stack</p>
          </div>
        </div>

        <ToggleDisplay show={this.state.show}>
 {/* 2nd row - cards */}
          <div className="cards-stack1">
            <div className="stack-row mb-5">
              
              <div className="col mb-4">
                <div className="card">
                  <h5>Wordpress</h5>
                </div>
              </div>
              
              <div className="col mb-4">
                <div className="card">
                  <h5>Wix</h5>
                </div>
              </div>
              
              <div className="col mb-4">
                <div className="card">
                  <h5>Airtable</h5>
                </div>
              </div>

            </div>
          </div>
        </ToggleDisplay>
        {/* 2nd row - end cards */}
      </div>
{/* end 2nd row - CMS */}
</React.Fragment>
  )
}
}
export default DkCard2