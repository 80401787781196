//import libraries
import React from "react";

import profiledk from "../images/photo_profil.jpg"

const AboutMe = () => (
  <React.Fragment>
    <div className="padding-top-id">
    <section className="section-centered-headline">
      <h1> hi! </h1>
    </section>
    <div className="aboutme-container">
      <img src={profiledk} alt="desktop-profile" className="aboutme-profile-img"/>
      <section className="aboutme-text">
        <p> I am a <b>front-end developer {"&"} designer </b> with 10-years of marketing experience as a creative leader at large multinational corporations such as Coty, L'Oreal or Young {"&"} Rubicam. 
        I utilize my <b>extensive marketing background</b> in everything I code or design so I can create a user-centered products that align with client business objectives.</p>
      </section>
    </div>
    </div>
  </React.Fragment>
)

export default AboutMe 

