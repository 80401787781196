//import libraries
import React from "react";
import {VerticleButton as ScrollUpButton} from "react-scroll-up-button";
import MediaQuery from 'react-responsive';
import { Link } from "gatsby";
// import { navigate } from "gatsby"

//  search
import SEO from "../components/seo";

//import styling
import "../styles/global.scss";
import 'bootstrap/dist/css/bootstrap.min.css';

//import components
import Navbar from "../components/navbar";
import NavbarMobile from "../components/mb-navbar";
import AboutMe from "../components/about-me";
import AboutMeMobile from "../components/mb-about-me";
import DkCard1 from "../components/dk-card1";
import DkCard2 from "../components/dk-card2";
import DkCard3 from "../components/dk-card3";
import Portfolio1 from "../components/portfolio1";
import Portfolio2 from "../components/portfolio2";
import Portfolio3 from "../components/portfolio3";
import Portfolio4 from "../components/portfolio4";
import Portfolio5 from "../components/portfolio5";
import ContactMessage from "../components/contact-message";
import Footer from "../components/footer";

//import imagery
import desktopbg from "../images/home_cubism_bg-min.jpg";
import mobilebg from "../images/home_cubism_bg_mobile_1-min.jpg";

class Home extends React.Component {

  // componentDidMount() {
  //     window.onbeforeunload = function() {
  //         this.onUnload();
  //         return "";
  //     }.bind(this);
  //     navigate("/"); // redirect to your root page, DidMount to go back on home when hard refresh
  // }

  render() {
    return (
      <React.Fragment>
        <SEO title="kristyna dierstein's portfolio" keywords={[`kristyna dierstein`, `front-end developer`, `graphic design`, `portfolio`]} />
          <div>
            <div className="container-body">

              <MediaQuery minDeviceWidth={1200}>
                <Navbar />
                <ScrollUpButton ContainerClassName='ScrollUpButton__Container'  ToggledStyle={{right: 10}} StopPosition={0}/>
              </MediaQuery>

              <MediaQuery maxDeviceWidth={599}>
                <NavbarMobile />
                <ScrollUpButton  ContainerClassName='ScrollUpButton__Container' ToggledStyle={{right: 10}} StopPosition={0}/>
              </MediaQuery> 

              <MediaQuery minDeviceWidth={1200}>
                <div className="flexbox-home">
                  <div className="div-home-img">
                    <img src={desktopbg} alt="desktop-img"className="desktop-img" />
                  </div>
                  <div className="home-centered-text">
                      <h1>KRISTYNA DIERSTEIN</h1>
                      <p>front-end developer</p>
                      <Link to="/#about-me" className="btn-home"><span> - </span><span>let's start</span><span> - </span></Link>
                  </div>
                </div>
              </MediaQuery>

                {/* <div className="desktop-bg no-padding">
                  <img src={desktopbg} alt="desktop-img" className="desktop-img" />
                </div>
                <div className="div-home-dk"> 
                  <div className="home-centered-text">
                    <h1>KRISTYNA DIERSTEIN</h1>
                    <p>front-end developer</p>
                    <Link to="/#about-me" className="btn-home"><span>let's start</span></Link>
                  </div>
                </div> */}

              <MediaQuery maxDeviceWidth={1199}>
                <div className="flexbox-home">
                  <div className="div-home-img">
                    <img src={mobilebg} alt="desktop-img"className="desktop-img opacity" />
                  </div>
                  <div className="home-centered-text">
                      <h1>KRISTYNA DIERSTEIN</h1>
                      <p>front-end developer</p>
                      <Link to="/#about-me" className="btn-home-mobile"><span> - </span><span>let's start</span><span> - </span></Link>
                  </div>
                </div>
              </MediaQuery>

                {/* <div className="mobile-bg no-padding">
                  <img src={mobilebg} alt="mobile-img" className="mobile-img" />
                </div>
                <div className="div-home-mb"> 
                  <div className="home-centered-text">
                    <h1>KRISTYNA DIERSTEIN</h1>
                    <p>front-end developer</p>
                    <Link to="/#about-me" className="btn-home-mobile"><span>let's start</span></Link>
                  </div>
                </div> */}
            </div>
              
            <div id="about-me">
              <MediaQuery minDeviceWidth={600}>
                <AboutMe />
                <DkCard1 />
                <DkCard2 />
                <DkCard3 />
              </MediaQuery>
              
              <MediaQuery maxDeviceWidth={599}>
                <AboutMeMobile />
                <DkCard1 />
                <DkCard2 />
                <DkCard3 />
              </MediaQuery>
            </div>

            <div className="container-portfolio" id="work">
              <div className="padding-top-id">
                  <Portfolio1 />
                  <Portfolio2 />
                  <Portfolio3 />
                  <Portfolio4 />
                  <Portfolio5 />
              </div>
            </div>
            
            <div id="contact">
              <ContactMessage />
            </div>
          </div>
        <Footer />
      </React.Fragment>
  )
  }
};

export default Home;

