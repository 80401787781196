//import libraries
import React from "react";


const ContactMessage = () => {

  return (
  <div className="contact-div padding-top-id">
    <img src={"https://res.cloudinary.com/kristynadierstein/image/upload/v1579055098/portfolio/contact_zufg7e.svg"} alt="" className="contact-img"/>
  </div>
  )
}

export default ContactMessage 

