//import libraries
import React from "react";
import Modal from 'react-responsive-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "gatsby";
import MediaQuery from 'react-responsive';
import {Motion, spring} from 'react-motion';


class Portfolio1 extends React.Component {
  constructor () {
    super();
    this.state = {
      open: false,
      isHover: false,
      activeItem: null
    };
    this.handleHover = this.handleHover.bind(this);
    this.getSpringProps = this.getSpringProps.bind(this);
  }
  
  handleHover(item) {
    this.setState({activeItem: item, isHover: !this.state.isHover});
  }

  getSpringProps() {
    return {
      defaultStyle: {
        scale: 1.1,
        marginTop: 30,
        imageOpacity: 0.9,
        opacity: 0,             
      },
      style:{
        scale: spring(this.state.isHover ? 1 : 1.1),
        marginTop: spring(this.state.isHover ? 30 : 30), 
        imageOpacity: spring(this.state.isHover ? 0.2 : 0.9),
        opacity: spring(this.state.isHover ? 1 : 0),
      },
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>
        <section className="section-centered-headline">
          <h1> let's have a look at some of my work </h1>
        </section>
{/* START desktop animated card with media query */}
        <MediaQuery minDeviceWidth={600}>
        <div
          data-sal-easing="ease"
          data-sal="slide-right" 
          data-sal-duration="1000"
          className="w-animated-card-L">

            <div className="img-relative">
              <img src={"https://res.cloudinary.com/kristynadierstein/image/upload/v1578433812/portfolio/bg-work-left_x5wv3v.svg"} alt="Maison Cedric" className="w-img-L"/>

              <div className="w-card-font w-card-abs-L">
                <p> MAISON CEDRIC
                <br/>
                <span>front-end development</span>
                </p>
                <Link className="btn-learn" to="/" onClick={this.onOpenModal}><span>see details</span></Link>
              </div>

            </div>
          </div>


        </MediaQuery>
{/* END desktop animated card with media query */}


{/* START mobile animated card with media query */}      
        <MediaQuery maxDeviceWidth={599}>
          <div className="animated-card-left">
          <Motion {...this.getSpringProps()}>
            {tweenCollection => {
            let styleImage = {
              transform: 'scale(' + tweenCollection.scale + ')',
              opacity: tweenCollection.imageOpacity, 
            };
            let styleTitle = {
                marginTop: tweenCollection.marginTop + '%',
                marginBottom: tweenCollection.marginBottom + '%',
                opacity: tweenCollection.opacity, 
            };
            return (
              <div className='subcontainer'>
              <div
                className='containerImage'
                onTouchStart={this.handleHover.bind(null, true)}>
                <img
                  style={styleImage}
                  src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579566237/portfolio/mobil/mb-portfolio-mc_jmkkb4.jpg'}
                  alt="Maison Cedric"
                  className='img img-left img-mobile' />
                <div className='overlay'>
                  <div className='title'>Maison Cedric</div>
                  <div className='subtitle' style={styleTitle}> front-end development <br/> React.js {'&'} Airtable
                    <div className="subtitleText"><Link className="btn-learn-motion" to="/" onClick={this.onOpenModal}>see details</Link></div>
                  </div>
                </div>
              </div>
            </div>
            );
          }}
        </Motion>
        </div>
      </MediaQuery>
{/* END mobile animated card with media query */}

{/* START modal with carousel */} 

      <Modal open={open} onClose={this.onCloseModal} closeIconSize={20} closeIcon="closeIcon" center>
        <Carousel
        showThumbs={false}
        showStatus={false}>
          <div>
            <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125122/portfolio/carousel/carousel-mc-1_ohca9b.jpg'} alt="carousel-mc-1"/>
          </div>
          <div>
            <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125122/portfolio/carousel/carousel-mc-2_wdld2o.jpg'} alt="carousel-mc-2"/>
          </div>
          <div>
            <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125122/portfolio/carousel/carousel-mc-3_i9h0ae.jpg'} alt="carousel-mc-3"/>
          </div>
          <div>
            <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125122/portfolio/carousel/carousel-mc-4_hdyq6m.jpg'} alt="carousel-mc-4"/>
          </div>
        </Carousel>
        <div className="legend-modal">
          <div>
            <h5>
            <b>Maison Cedric</b>
            </h5>
            <p > 
            Complete website refresh for a French interior design company. 
            <br/>
            Dynamic website displaying current client's portfolio with item's price and its status. 
            <br/>
            Built with React.js, Gatsby and Airtable.</p>
            <div className="div-btn-modal">
              <p><a href={"https://maisoncedric.netlify.com"} className="btn-learn-modal">view on Netlify </a></p> 
              <p><a href={"https://github.com/kristynadierstein/maisoncedric"} className="btn-learn-modal">view on GitHub </a></p>
            </div>
          </div>
        </div>
      </Modal>       
{/* END modal with carousel */} 
    </React.Fragment>
    );
  }
}

export default Portfolio1



