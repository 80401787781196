//import libraries
import React from "react";
import Modal from 'react-responsive-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "gatsby";
import MediaQuery from 'react-responsive';
import {Motion, spring} from 'react-motion';

//import icons
import {FaYoutube} from "react-icons/fa";


class Portfolio5 extends React.Component {
  constructor() {
  super();
    this.state = {
      open: false,
      isHover: false,
      activeItem: null
    };
  this.handleHover = this.handleHover.bind(this);
  this.getSpringProps = this.getSpringProps.bind(this);
  }
  
  handleHover(item) {
    this.setState({activeItem: item, isHover: !this.state.isHover});
  }

  getSpringProps() {
    return {
      defaultStyle: {
        scale: 1.15,
        marginTop: 30,
        imageOpacity: 0.9,
        opacity: 0,             
      },
      style:{
        scale: spring(this.state.isHover ? 1 : 1.15),
        marginTop: spring(this.state.isHover ? 30 : 30), 
        imageOpacity: spring(this.state.isHover ? 0.2 : 0.9),
        opacity: spring(this.state.isHover ? 1 : 0),
      },
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>
{/* START desktop animated card with media query */}              
            <MediaQuery minDeviceWidth={600}>
              <div 
                data-sal="slide-right" 
                data-sal-easing="ease"
                data-sal-duration="1000"
                className="w-animated-card-L">
               
                <div className="img-relative">
                  <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1578509608/portfolio/bg-work-left-5_r4zowu.svg'} alt="Max Factor" className="w-img-L"/>
                    <div className="w-card-font mf-specific">
                      <p> MAX FACTOR
                      <br/>
                      <span>marketing digital campaign</span>
                      </p>
                      <Link className="btn-learn" to="/" onClick={this.onOpenModal}><span>see details </span></Link>
                    </div>
                </div>

              </div>
            </MediaQuery>
{/* END desktop animated card with media query */}            
            
            <MediaQuery maxDeviceWidth={599}>
              <div className="animated-card-left">
                <Motion {...this.getSpringProps()}>
                  {tweenCollection => {
                  let styleImage = {
                    transform: 'scale(' + tweenCollection.scale + ')',
                    opacity: tweenCollection.imageOpacity, 
                  };
                  let styleTitle = {
                      marginTop: tweenCollection.marginTop + '%',
                      opacity: tweenCollection.opacity,
                  };
                  return (
                    <div className='subcontainer'>
                      <div
                        className='containerImage'
                        onTouchStart={this.handleHover.bind(null, true)}>
                        <img
                          style={styleImage}
                          src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579566529/portfolio/mobil/portfolio-mf_sy6q0e.jpg'}
                          alt="Max Factor"
                          className='img img-left img-mobile' />
                        <div className='overlay'>
                          <div className='title'>Max Factor</div>
                            <div className='subtitle' style={styleTitle}> #myfactor <br/> marketing digital campaign
                              <div className='subtitleText'><Link className="btn-learn-motion" to="/" onClick={this.onOpenModal}>see details</Link></div>
                            </div>
                        </div>
                      </div>
                    </div>
                  );
                  }}
                </Motion>
              </div>
            </MediaQuery>

          <Modal open={open} onClose={this.onCloseModal} closeIconSize={20} closeIcon="closeIcon" center>
            <div>
              <video 
                muted
                playsInline
                controls
                style={{ width: `100%` }} >
                <source src={"https://res.cloudinary.com/kristynadierstein/video/upload/v1579615835/portfolio/video/mf-mf-video_en5wvw.mp4"} type="video/mp4" />
                <source src={"https://res.cloudinary.com/kristynadierstein/video/upload/v1579615842/portfolio/video/mf-mf-video_wwmrlq.webm"} type="video/webm" />
                <track kind="metadata" src="#" srcLang="en" label="MfMf1"/>
                Sorry, your browser doesn't support embedded videos.
              </video>
              <div className="legend-modal">
                <h5>
                  <b>#myfactor</b> 
                </h5>
                <p> 
                Complete influencer-driven digital campaign to raise brand awareness in Northern Europe (SE, DK, NO, FI). 
                <br />
                Management of creative and media agencies, digital specialists, PR partners and well-known influencers across region. 
                <br />
                Net impact of 12 Mio impressions within the target group and achieved business objective of 5{"%"}MS vs PY.  </p>
                <a href={"https://www.youtube.com/watch?v=5czc3_ghgI8&t=4s"} className="yt-btn"><FaYoutube /></a>
              </div>
            </div>
          </Modal>
      </React.Fragment>
    );
  }
}


export default Portfolio5



