//import libraries
import React from "react";
import Scrollspy from 'react-scrollspy'


class Navbar extends React.Component {
  constructor () {
    super();
  this.state = {
    class: 'navbar-top'
  }
}

  listenScrollEvent = e => {
    if (window.scrollY > 900) {
      this.setState({class: 'fixed'})
    } else {
      this.setState({class: 'navbar-top'})
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.listenScrollEvent)
  }
  
  render () {
    return (
      <nav className={this.state.class} >
        <Scrollspy items={ ['about-me', 'work', 'contact'] } className="nav justify-content-center" currentClassName="is-current nav justify-content-center">
        <li className="nav-item">
          <a className="nav-link navigation-text p-1 mr-5" href="#about-me">About</a>
        </li>
        <li className="nav-item">
          <a className="nav-link navigation-text p-1 mr-5" href="#work">Work</a>
        </li>
        <li className="nav-item" >
          <a className="nav-link navigation-text p-1 mr-5" href="#contact">Contact</a>
        </li>
      </Scrollspy>
    </nav>
    )
  }
}


export default Navbar


