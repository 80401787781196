//import libraries
import React from "react";
import Modal from 'react-responsive-modal';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { Link } from "gatsby";
import MediaQuery from 'react-responsive';
import {Motion, spring} from 'react-motion';

class Portfolio2 extends React.Component {
  constructor () {
    super();
    this.state = {
      open: false,
      isHover: false,
      activeItem: null
    };
    this.handleHover = this.handleHover.bind(this);
    this.getSpringProps = this.getSpringProps.bind(this);
  }

  handleHover(item) {
    this.setState({activeItem: item, isHover: !this.state.isHover});
  }

  getSpringProps() {
    return {
      defaultStyle: {
        scale: 1.15,
        marginTop: 30,
        imageOpacity: 0.9,
        opacity: 0,             
      },
      style:{
        scale: spring(this.state.isHover ? 1 : 1.15),
        marginTop: spring(this.state.isHover ? 30 : 30), 
        imageOpacity: spring(this.state.isHover ? 0.2 : 0.9),
        opacity: spring(this.state.isHover ? 1 : 0),
      },
    };
  }

  onOpenModal = () => {
    this.setState({ open: true });
  };
 
  onCloseModal = () => {
    this.setState({ open: false });
  };

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>   
{/* START desktop animated card with media query */}            
        <MediaQuery minDeviceWidth={600}>
          <div 
            data-sal-easing="ease"
            data-sal="slide-left" 
            data-sal-duration="1000"
            className="w-animated-card-R">

            <div className="img-relative">
            <img src={"https://res.cloudinary.com/kristynadierstein/image/upload/v1579350096/portfolio/mb-portfolio-nf_cahkol.svg"} alt="Noor Fes" className="w-img-R" />
              
              <div className="w-card-font w-card-R">
                <p> NOOR FES
                <br/>
                <span>UX / UI design</span>
                </p>
                <Link className="btn-learn" to="/" onClick={this.onOpenModal}><span>see details</span></Link>
              </div>
              
            </div>
          </div>
        </MediaQuery>
{/* END desktop animated card with media query */}

          <MediaQuery maxDeviceWidth={599}>
            <div className="animated-card-right">
              <Motion {...this.getSpringProps()}>
                {tweenCollection => {
                let styleImage = {
                  transform: 'scale(' + tweenCollection.scale + ')',
                  opacity: tweenCollection.imageOpacity, 
                };
                let styleTitle = {
                    marginTop: tweenCollection.marginTop + '%',
                    opacity: tweenCollection.opacity, 
                };

                return (
                  <div className='subcontainer'>
                  <div
                    className='containerImage'
                    onTouchStart={this.handleHover.bind(null, true)}>
                    <img
                      style={styleImage}
                      src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579566351/portfolio/mobil/mb-portfolio-nf_dmqycu.jpg'}
                      alt="Maison Cedric"
                      className='img img-right img-mobile' />
                    <div className='overlay'>
                      <div className='title'>Noor Fes</div>
                      <div className='subtitle' style={styleTitle}>UX/UI design <br/> Figma
                        <div className='subtitleText'><Link className="btn-learn-motion" to="/" onClick={this.onOpenModal}>see details</Link></div>
                      </div>
                    </div>
                  </div>
                  </div>
                );
                }}
              </Motion>
            </div>
          </MediaQuery>

          <Modal open={open} onClose={this.onCloseModal} closeIconSize={20} closeIcon="closeIcon" center>
            <Carousel
              showThumbs={false}
              showStatus={false}  
              dynamicHeight={true}>
              <div>
                <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125122/portfolio/carousel/carousel-noor-1_ggxea5.jpg'} alt="carousel-noor-1"/> 
              </div>
              <div>
                <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125122/portfolio/carousel/carousel-noor-2_xvuqsx.jpg'} alt="carousel-noor-2"/>
              </div>
              <div>
                <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125123/portfolio/carousel/carousel-noor-3_njpogt.jpg'} alt="carousel-noor-3"/>
              </div>
              <div>
                <img src={'https://res.cloudinary.com/kristynadierstein/image/upload/v1579125122/portfolio/carousel/carousel-noor-4_r5bjhb.jpg'} alt="carousel-noor-4"/>
              </div>
            </Carousel>
            <div className="legend-modal">
              <div>
                <h5>
                <b>Noor Fes</b>
                </h5>
                <p>
                Premium olive oil company from Morocco producing high-quality product in a sustainable way. 
                <br/>
                User experience analysis with a new design proposal in Figma. 
                <br/>
                Enhanced design responds better to client's business objectives and improves the usability and accessibility for users.</p>
                <div className="div-btn-modal">
                  <p><a href={"https://www.figma.com/file/Zu0jOEuY5nlnpVXnTRXmTC/Noor-F%C3%A8s?node-id=0%3A1"} className="btn-learn-modal">view in Figma </a> </p>
                </div>
              </div>
            </div>
          </Modal>
      </React.Fragment>
    );
  }
}

export default Portfolio2



